import { Popover, Transition } from '@headlessui/react'
import { GoogleMap, useLoadScript } from '@react-google-maps/api'
import React from 'react'
import CaretDownIcon from '../../../assets/svgs/caret-down.svg'
import XIcon from '../../../assets/svgs/x.svg'
import { Trans, useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { dialogType } from '../../../types/app'
import { dispatch, IPalletData, useGlobalState } from '../../state'
import { ButtonGhost, ButtonOutlineSmall } from '../buttons'
import MarkerComponent from './marker'
import mapStyles from './styles'

const mapCenter = { lat: 28.6947293, lng: -13.9262996 }
const zoom = 12

export interface MapProps {
  fullscreen?: boolean
}

const Map: React.FC<MapProps> = ({ fullscreen }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY!
  })

  const [palletDataLoading] = useGlobalState('palletDataLoading')
  const [palletData] = useGlobalState('palletData')
  const [currentPallet] = useGlobalState('currentPallet')

  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null)
  const [lastPallet, setLastPallet] = React.useState<IPalletData>({
    title: '',
    lat: '',
    lng: '',
    status: 'planned'
  })
  const { t } = useTranslation('translation')
  const [userLocation, setUserLocation] = React.useState<{ lat: number; lng: number } | null>(null)

  const [paletteDetailsOpen, setPaletteDetailsOpen] = React.useState(false)

  const setCurrentPallet = (pallet: IPalletData | null) => {
    dispatch({ type: 'setCurrentPallet', value: pallet })
  }

  const handleMapLoaded = (map: google.maps.Map) => {
    if (palletData) {
      const bounds = new google.maps.LatLngBounds()
      palletData.forEach((p) => {
        const latLng = new google.maps.LatLng(parseFloat(p.lat), parseFloat(p.lng))
        bounds.extend(latLng)
      })
      map.fitBounds(bounds)
      setMapInstance(map)
    }
  }

  React.useEffect(() => {
    let isActive = true
    if (mapInstance && isActive) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (palletData && mapInstance && isActive) {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          })
          const bounds = new google.maps.LatLngBounds()
          palletData.forEach((p) => {
            const latLng = new google.maps.LatLng(parseFloat(p.lat), parseFloat(p.lng))
            bounds.extend(latLng)
          })
          const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          bounds.extend(latLng)
          mapInstance.fitBounds(bounds)
        }
      })
    }
    return () => {
      isActive = false
    }
  }, [palletData, mapInstance])

  const openFeedbackDialog = (type: dialogType) => {
    dispatch({
      type: 'setDialogState',
      value: type
    })
  }

  const getPalletStatus = (status: 'planned' | 'installed') => {
    // i18next-extract-mark-context-next-line ["installed", "planned"]
    return t('status', { context: status })
  }

  const handleClosePalletDetails = () => {
    if (currentPallet) {
      setPaletteDetailsOpen(false)
      setLastPallet(currentPallet)
      setCurrentPallet(null)
    }
  }

  const renderMap = () => {
    return (
      <div
        className={`relative flex flex-col overflow-hidden md:flex-row ${
          fullscreen ? 'h-full' : 'my-24 border-t border-b border-teal-700 h-160'
        }`}
      >
        {!palletDataLoading && palletData && (
          <GoogleMap
            zoom={zoom}
            center={mapCenter}
            mapContainerStyle={{
              height: '100%',
              width: '100%',
              order: 2
            }}
            options={{ styles: mapStyles }}
            onLoad={handleMapLoaded}
            onClick={() => {
              if (currentPallet) {
                setPaletteDetailsOpen(false)
                setLastPallet(currentPallet)
                setCurrentPallet(null)
              }
            }}
          >
            {userLocation && (
              <MarkerComponent
                active={false}
                lat={userLocation.lat}
                lng={userLocation.lng}
                status="user"
              />
            )}
            {palletData.map((location, index) => (
              <MarkerComponent
                key={`${location.lat},${location.lng}`}
                lat={parseFloat(location.lat)}
                lng={parseFloat(location.lng)}
                index={index}
                active={location === currentPallet}
                status={location.status}
                onClick={() => {
                  setPaletteDetailsOpen(true)
                  setCurrentPallet(location)
                }}
              />
            ))}
          </GoogleMap>
        )}
        {paletteDetailsOpen && (
          <div className="absolute z-10 w-full h-full bg-white md:w-80">
            <div className="px-4 py-8 space-y-4">
              <h3 className="mr-8 text-primary">
                {currentPallet ? currentPallet.title : lastPallet.title}
              </h3>
              <dl className="grid grid-cols-2 gap-y-2">
                <dt className="text-primary">Longitude</dt>
                <dd className="font-bold text-right text-primary">
                  {currentPallet ? currentPallet.lng : lastPallet.lng}
                </dd>
                <dt className="text-primary">Latitude</dt>
                <dd className="font-bold text-right text-primary">
                  {currentPallet ? currentPallet.lat : lastPallet.lat}
                </dd>
              </dl>
              <div
                className={`inline-block h-6 px-2 py-1 text-sm leading-none text-white border rounded ${
                  (currentPallet && currentPallet.status === 'installed') ||
                  lastPallet.status === 'installed'
                    ? 'bg-red-500 border-red-500'
                    : 'bg-gray-500 border-gray-500'
                }`}
              >
                <Trans>Status:</Trans>{' '}
                <span>
                  {getPalletStatus(currentPallet ? currentPallet.status : lastPallet.status)}
                </span>
              </div>
              {(currentPallet && currentPallet.status === 'installed') ||
              lastPallet.status === 'installed' ? (
                <Popover>
                  <Popover.Button aria-label="{t('Report an issue')}" as={ButtonOutlineSmall}>
                    <span>
                      <Trans>Report an issue</Trans>
                    </span>
                    <CaretDownIcon width="16px" height="16px" className="ml-2 stroke-current" />
                  </Popover.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Popover.Panel className="absolute p-4 mt-4 space-y-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                      <ButtonGhost
                        className="w-full h-8"
                        onClick={() => {
                          openFeedbackDialog('dialogFull')
                        }}
                      >
                        <Trans>Pallet is full</Trans>
                      </ButtonGhost>
                      <ButtonGhost
                        className="w-full h-8"
                        onClick={() => {
                          openFeedbackDialog('dialogBroken')
                        }}
                      >
                        <Trans>Pallet is broken</Trans>
                      </ButtonGhost>
                      <ButtonGhost
                        className="w-full h-8"
                        onClick={() => {
                          openFeedbackDialog('dialogGone')
                        }}
                      >
                        <Trans>Pallet is gone</Trans>
                      </ButtonGhost>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ) : null}
            </div>
            <ButtonGhost
              className="!absolute top-0 right-0 w-10 px-0 focus:outline-none"
              onClick={handleClosePalletDetails}
            >
              <XIcon width="24" height="24" className="text-teal-700 stroke-current" />
            </ButtonGhost>
          </div>
        )}
      </div>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <div className="inset-0 flex items-center justify-center">Loading</div>
  )
}

export default Map

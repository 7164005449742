import { Marker } from '@react-google-maps/api'
import React from 'react'

export interface MarkerComponentProps {
  lat: number
  lng: number
  status: 'planned' | 'installed' | 'user'
  active: boolean
  index?: number
  onClick?: (index: number) => void
}

const icons: { [key: string]: any } = {
  planned: '/images/MARKER_PLANNED.png',
  installed: '/images/MARKER_INSTALLED.png',
  user: '/images/MARKER_USER.png'
}

const MarkerComponent: React.FC<MarkerComponentProps> = ({
  lat,
  lng,
  active,
  index,
  status,
  onClick
}) => {
  // const map = useGoogleMap()

  // React.useEffect(() => {
  //   if (map && active) {
  //     map.setZoom(16)
  //     map.panTo({ lat, lng })
  //   }
  // }, [map, active])

  return (
    <Marker
      position={{ lat, lng }}
      onClick={() => {
        if (index !== undefined && onClick) {
          onClick(index)
        }
      }}
      icon={{
        url: icons[status],
        scaledSize: active ? new google.maps.Size(33, 54) : new google.maps.Size(22, 36)
      }}
    />
  )
}

export default MarkerComponent

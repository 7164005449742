import React from 'react'
import { Trans, useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { useGlobalState } from '../../state'
import { Button } from '../buttons'

const encode = (data: { [key: string]: any }) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

export interface FormProps {
  formName: string
  messageSubject: string
  headline: string
  copytext: string
}

const FormIssue: React.FC<FormProps> = ({ formName, messageSubject, headline, copytext }) => {
  const { t } = useTranslation('translation')
  const [palletDataLoading] = useGlobalState('palletDataLoading')
  const [palletData] = useGlobalState('palletData')
  const [currentPallet] = useGlobalState('currentPallet')

  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pallet, setPallet] = React.useState('')
  const [email, setEmail] = React.useState('')

  React.useEffect(() => {
    if (currentPallet) {
      setPallet(`${currentPallet.title}: ${currentPallet.lat} / ${currentPallet.lng}`)
    } else {
      setPallet('')
    }
  }, [currentPallet])

  const handleSubmit = (e: React.FormEvent) => {
    const formData = {
      messageSubject,
      email,
      pallet
    }
    // console.log('submit', formName, formData);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        ...formData
      })
    })
      .then(() => {
        // console.log('submit went through', response);
        setSuccess(true)
      })
      .catch(() => {
        setError(true)
      })

    e.preventDefault()
  }

  if (palletDataLoading) {
    return (
      <div className="mx-auto">
        <div className="p-6 bg-white border border-gray-300 rounded">
          <Trans>Loading pallet data. Give us a moment.</Trans>
        </div>
      </div>
    )
  }

  if (success) {
    return (
      <div className="mx-auto">
        <h3 className="mb-2 text-primary">
          <Trans>Thank you!</Trans>
        </h3>
        <p className="text-primary">
          <Trans>
            We received your message and will take care of it. If you sent us a general inquiry
            someone will get in touch soon.
          </Trans>
        </p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="mx-auto">
        <h3 className="mb-2 text-primary">
          <Trans>Snap, this didn't work.</Trans>
        </h3>
        <p className="text-primary">
          <Trans>
            The message could not be sent. Should the problem persist, please send your message via
            mail to:
          </Trans>
          <a href={`mailto:${process.env.GATSBY_FALLBACK_MAIL}`}>
            {process.env.GATSBY_FALLBACK_MAIL}
          </a>
        </p>
      </div>
    )
  }

  return (
    <div className="mx-auto">
      <h3 className="mb-4 text-5xl leading-none text-center uppercase text-primary">{headline}</h3>
      <p className="mb-8 text-center text-primary">{copytext}</p>
      <form onSubmit={handleSubmit}>
        <div>
          <select
            className="w-full h-12 px-4 mb-3 border border-gray-400 rounded sm:mb-6"
            name="pallet"
            id="pallet"
            value={pallet}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setPallet(e.target.value)
            }}
          >
            <option value="" disabled>
              {t('Select a location')}
            </option>
            {palletData &&
              palletData.map((p) => (
                <option key={`${p.lat}-${p.lng}`} value={`${p.title}: ${p.lat} / ${p.lng}`}>
                  {p.title}
                </option>
              ))}
          </select>
        </div>
        <div>
          <input
            className="w-full h-12 px-4 mb-3 border border-gray-400 rounded sm:mb-6"
            type="text"
            name="email"
            id="email"
            placeholder={t('Your eMail address')}
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              // console.log('setEmail', e.target.value);
              setEmail(e.target.value)
            }}
          />
        </div>
        <Button className="w-full" type="submit" disabled={email === '' || pallet === ''}>
          <Trans>Submit message</Trans>
        </Button>
      </form>
    </div>
  )
}

export default FormIssue

import { Dialog } from '@headlessui/react'
import React from 'react'

export interface ModalFeedbackProps {
  isOpen: boolean
  onClose: () => void
}

const ModalFeedback: React.FC<ModalFeedbackProps> = ({ children, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50" />
        <div className="relative max-w-md p-12 mx-auto bg-white rounded">{children}</div>
      </div>
    </Dialog>
  )
}

export default ModalFeedback
